<template>
  <div class="footer">
    © {{ currentYear }} CyberFish Studio Ltd.  All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 114px;
  color:#FFFFFF;
  background: #14404E;

  font-weight: 400;
  font-size: 0.4rem;
}
</style>
