import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'

const htmlFontSize = () => {
  if (parseInt(document.documentElement.style.fontSize.replace('px', '')) > 54) {
    const autoHeight = parseInt(window.innerHeight * 750 / 1334 / 10)
    if (autoHeight > 54) {
      document.documentElement.style.fontSize = '54px'
    } else {
      document.documentElement.style.fontSize = autoHeight + 'px'
    }
  }
}

htmlFontSize()
window.addEventListener('resize', function () {
  htmlFontSize()
})


createApp(App).mount('#app')
