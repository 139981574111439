<template>
  <div class="content">
    <div class="image-block">
      <img src="../assets/PP_Design_robot_fish.jpg" alt="" srcset="">
    </div>
    <div class="text-block">
      <div class="title">
        WHO WE ARE
      </div>
      <div class="text">
        CyberFish Studio Ltd is an innovative and forward-thinking game development company with a passion for creating high-quality, immersive, and engaging gaming experiences. 
        Established in 2023, the company has quickly gained a reputation for its cutting-edge technology and artistic prowess in the gaming industry.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheContent',
}
</script>

<style lang="scss" scoped>
.content {  
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 26px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    padding: 54px 0;
  }

  .image-block {
    width: 50%;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  .text-block {
    width: 30%;
    padding: 22px;

    @media only screen and (max-width: 1300px) {
      width: calc(100% - 50px);
      padding: 40px 68px;
    }
  }
  .title {
    font-weight: 700;
    font-size: 1.1rem;
    color: #25A6CF;
    text-align: left;
    margin-bottom: 40px;
  }

  .text {
    text-align: left;
    font-weight: 700;
    font-size: 0.4rem;
    line-height: 227%;

  }

  img {
    max-width: 100%;
  }
}
</style>
