<template>
  <div class="header">
    <img src="../assets/cyberfish_logo.png" alt="" srcset="">
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 100px;
  background: #14404E;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 75%;
  }
}
</style>
